<template>
  <Layout>
    <div class="container mt-4 py-5">
      <div class="row">
        <div class="col-12 col-lg-3 mb-3">
          <h2 class="color-institucional">Votaciones</h2>

          <b-list-group>
            <b-list-group-item
              to="/centro-de-ayuda/votaciones/votos-coalicion"
            >
              Votos en Coalición
            </b-list-group-item>
            <b-list-group-item
              to="/centro-de-ayuda/votaciones/casillas-electorales"
              active
            >
              Casillas Electorales
            </b-list-group-item>
            <b-list-group-item
              to="/centro-de-ayuda/votaciones/participacion-ciudadana"
            >
              Participación Ciudadana
            </b-list-group-item>
            <b-list-group-item
              to="/centro-de-ayuda/votaciones/lista-nominal"
            >
              Lista Nominal
            </b-list-group-item>
          </b-list-group>
        </div>
        <div class="col-12 col-lg-9">
          <b-breadcrumb>
            <b-breadcrumb-item to="/centro-de-ayuda" class="color-terciario">
              Inicio
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              Votaciones
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              <b>
                Casillas Electorales
              </b>
            </b-breadcrumb-item>
          </b-breadcrumb>

          <h2 class="text-center color-institucional">
            Casillas Electorales
          </h2>

          <p>
            Las casillas electorales se instalan para recibir a las y los
            electores que deseen ejercer su derecho al voto y elegir a sus representantes.
          </p>

          <h4 class="text-center color-institucional">
            ¿Cuáles son los tipos de Casillas que existen?
          </h4>

          <div class="row mb-4">
            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/votaciones/casilla-1.svg" alt="" class="img-fluid mx-auto d-block">

              <p class="font-weight-bold text-center">
                Casilla básica
              </p>

              <p class="text-center">
                Es la casilla correspondiente a una sección electoral,
                la cual recibe un número no mayor a 750 electores.
              </p>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/votaciones/casilla-2.svg" alt="" class="img-fluid mx-auto d-block">

              <p class="font-weight-bold text-center">
                Casilla contigua
              </p>

              <p class="text-center">
                Si en una sección existen más de 750 personas electoras se instalan tantas
                casillas contiguas como se requiera. Cada una de estas casillas también
                recibirán un máximo de 750 personas electoras.
              </p>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/votaciones/casilla-3.svg" alt="" class="img-fluid mx-auto d-block">

              <p class="font-weight-bold text-center">
                Casilla extraordinaria
              </p>

              <p class="text-center">
                Su instalación facilita el acceso de las y los electores,
                a quienes por condiciones geográficas, de infraestructura o
                socioculturales no pueden votar en la sección que les corresponde.
              </p>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/votaciones/casilla-4.svg" alt="" class="img-fluid mx-auto d-block">

              <p class="font-weight-bold text-center">
                Casilla especial
              </p>

              <p class="text-center">
                Se instalan para las y los electores que se
                encuentren fuera de la sección electoral correspondiente a su domicilio.
              </p>
            </div>

          </div>

          <h4 class="text-center color-institucional">
            ¿Qué es una Casilla única?
          </h4>

          <div class="row mb-4 align-items-center">
            <div class="col-12 col-lg-3 mb-3">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/votaciones/casilla-5.svg" alt="" class="img-fluid mx-auto d-block">
            </div>
            <div class="col-12 col-lg-9 mb-3">
              <p class="text-center">
                La casilla única solo se instala cuando existen
                votaciones tanto para cargos Federales como Locales
                para que las ciudadanas y ciudadanos puedan votar en una misma casilla.
              </p>
            </div>
          </div>

          <h4 class="text-center color-institucional">
            ¿Puedo votar en otra Casilla?
          </h4>

          <p>
            Si el día de la elección no te encuentras cerca de
            tu casilla puedes votar en casillas especiales, dependiendo de tu ubicación.
          </p>

          <div class="row mb-4 align-items-center">
            <div class="col-12 col-lg-5 mb-4">
              <p>
                Por los cargos de Diputaciones a nivel local y
                federal por el principio de Mayoría Relativa y Representación Proporcional en:
              </p>

              <ul>
                <li>
                  <b>Otra sección</b>, pero en el mismo distrito
                </li>
                <li>
                  <b>Otro Distrito</b>, pero en la misma Entidad
                </li>
                <li>
                  <b>Otra Entidad</b>, pero en la misma Circunscripción.
                </li>
              </ul>
            </div>
            <div class="col-12 col-lg-7 mb-4">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/votaciones/casilla-6.svg" alt="" class="img-fluid mx-auto d-block">
            </div>
          </div>

          <p class="text-center mb-5">
            <b>
              Referencias:
            </b>
            <br />
            · Artículo 82, 253 y 284 Ley General de Instituciones
            y Procedimientos Electorales. <br />
            · Artículo 235, 249 y 284 del Reglamento de Elecciones del Instituto Nacional Electoral.
          </p>

          <a
            href="https://ayuda.ine.mx/2021/informate/assets/docs/Votaciones/Casillas_Electorales.pdf"
            target="_blank"
            class="btn btn-primary mx-auto d-block"
          >
            Descarga Infografía
          </a>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/CentroDeAyuda/Layout.vue';

export default {
  components: {
    Layout,
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: disc;
}

.list-group-item {
  z-index: 0;
}

.grid {
  display: grid;
  align-items: center;
  gap: 45px;
  grid-template-columns: 50px 1fr;

  > img {
    max-width: 45px;
  }

  > p {
    margin-bottom: 0;
  }
}
</style>
